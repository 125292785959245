<template>
  <section>
    <b-overlay :show="loadedHangarVariantLoading">
      <b-card class="border">
        <div class="d-none d-sm-flex justify-content-between">
          <app-select
            id="hangarVariantId"
            v-model="selectedHangarVariant"
            style="min-width: 300px;"
            :placeholder="$t('dashboard.hangar.hangar')"
            :options="hangarsVariantsOptions"
            :searchable="false"
            :clearable="false"
            v-bind="variantsDataMeta"
            paginated
            :overlay="hangarsVariantsOptionsLoading"
            @option:selected="fetchHangarsVariantsDashboard()"
            @click:previous-page="fetchHangarsVariants(variantsDataMeta.previousPage)"
            @click:next-page="fetchHangarsVariants(variantsDataMeta.nextPage)"
          >
            <template #selected-option="{ productName }">
              {{ productName | trans }}
            </template>
            <template #option="{ productName }">
              {{ productName | trans }}
            </template>
          </app-select>

          <b-button :to="{ name: 'hangar-calendar', query: { uniqueKey: `${Date.now()}` }}" variant="primary">
            {{ $t('dashboard.hangar.view_calendar') }}
          </b-button>
        </div>

        <div class="d-block d-sm-none">
          <app-select
            id="hangarVariantId"
            v-model="selectedHangarVariant"
            :placeholder="$t('dashboard.hangar.hangar')"
            :options="hangarsVariantsOptions"
            :searchable="false"
            :clearable="false"
            v-bind="variantsDataMeta"
            paginated
            :overlay="hangarsVariantsOptionsLoading"
            @option:selected="fetchHangarsVariantsDashboard()"
            @click:previous-page="fetchHangarsVariants(variantsDataMeta.previousPage)"
            @click:next-page="fetchHangarsVariants(variantsDataMeta.nextPage)"
          >
            <template #selected-option="{ productName }">
              {{ productName | trans }}
            </template>
            <template #option="{ productName }">
              {{ productName | trans }}
            </template>
          </app-select>

          <b-button class="mt-2" :to="{ name: 'hangar-calendar', query: { uniqueKey: `${Date.now()}` }}" variant="primary">
            {{ $t('dashboard.hangar.view_calendar') }}
          </b-button>
        </div>

        <b-card no-body class="border mb-0 mt-2">
          <div class="d-flex justify-content-between p-2">
            <div class="d-flex align-items-center">
              <div class="d-inline-flex px-1" style="cursor: pointer" @click="changeDate('subtract')">
                <feather-icon icon="ChevronLeftIcon" size="25" />
              </div>
              <div class="d-inline-flex px-1" style="cursor: pointer" @click="changeDate('add')">
                <feather-icon icon="ChevronRightIcon" size="25" />
              </div>
              <h2 class="d-inline-flex mb-0">
                {{ currentDate | moment('ddd, MMM Do YYYY') }}
              </h2>
            </div>

            <template v-if="loadedHangarVariant">
              <p id="total-aircrafts">{{ dashboard.totalAircrafts }} {{
                $tc('common.aircraft', dashboard.totalAircrafts)
              }}</p>
              <b-tooltip v-if="dashboard.slices" target="total-aircrafts" triggers="hover" placement="bottom">
                <div v-for="slice in dashboard.slices" :key="slice.sliceNumber">
                  {{ $t('dashboard.table.slice') }} {{ slice.sliceNumber }}: {{ slice.totalAircrafts }}
                </div>
              </b-tooltip>
            </template>
          </div>

          <div class="border p-1">{{ $t('trip.departure') }}</div>
          <app-data-table
            ref="departure"
            table-name="departure-table"
            :fields="fields"
            :items="dashboard.departures"
            :overlay="hangarsVariantsOptionsLoading || dashboardLoading"
          >
            <template #head(tow)>{{ $t('dashboard.table.tow_out') }}</template>
            <template #cell(tow)="{ item }">
              {{ $moment(item.towOut).utc().format('HH:mm') }}
            </template>
            <template #cell(sliceNumber)="{ item }">
              <span v-if="loadedHangarVariant.variantType === 'parking' && loadedHangarVariant.managementType === 'classic'"></span>
              <span v-else>{{ item.sliceNumber }}</span>
            </template>
            <template #cell(actions)="data">
              <app-data-table-actions
                :show-delete="false"
                :show-duplicate="false"
                :show-edit="$can('DASHBOARD_HANGAR_EDIT') && data.item.tripId === null"
                :show-show="$can('TRIP_VIEW') && data.item.tripId !== null"
                display-mode="inline-icon"
                table-name="refServiceListTable"
                @edit="editCustomEvent(data.item)"
                @show="$router.push({ name: 'trip-details', params: { trip_id: data.item.tripId } })"
              />
            </template>
          </app-data-table>

          <div class="border p-1">{{ $t('trip.arrival') }}</div>
          <app-data-table
            ref="arrival"
            table-name="arrivals-table"
            :fields="fields"
            :items="dashboard.arrivals"
            :overlay="hangarsVariantsOptionsLoading || dashboardLoading"
          >
            <template #head(tow)>{{ $t('dashboard.table.tow_in') }}</template>
            <template #cell(tow)="{ item }">
              {{ $moment(item.towIn).utc().format('HH:mm') }}
            </template>
            <template #cell(sliceNumber)="{ item }">
              <span v-if="loadedHangarVariant.variantType === 'parking' && loadedHangarVariant.managementType === 'classic'"></span>
              <span v-else>{{ item.sliceNumber }}</span>
            </template>
            <template #cell(actions)="data">
              <app-data-table-actions
                :show-delete="false"
                :show-duplicate="false"
                :show-edit="$can('DASHBOARD_HANGAR_EDIT') && data.item.tripId === null"
                :show-show="$can('TRIP_VIEW') && data.item.tripId !== null"
                display-mode="inline-icon"
                table-name="refServiceListTable"
                @edit="editCustomEvent(data.item)"
                @show="$router.push({ name: 'trip-details', params: { trip_id: data.item.tripId } })"
              />
            </template>
          </app-data-table>
        </b-card>
      </b-card>

      <app-sidebar-form
      form-name="edit-event"
      :edition="customEventSibebarOpen"
      :title="$tc('common.aircraft', 1)"
      :external-request="onSubmit"
      :entity="customEventSelected"
      :show-submit="$can('DASHBOARD_HANGAR_EDIT')"
      :show-reset="$can('DASHBOARD_HANGAR_EDIT')"
      :show-delete="$can('DASHBOARD_HANGAR_DELETE')"
      @delete="modalDeletecustomTrip(customEventSelected.event.id)"
      @update:edition="val => {
        if (!val) {
          customEventSelected = null
          customEventSibebarOpen = false
        }
      }"
    >
      <template #fields="{ item }">
        <div class="mb-2">
          <div class="mb-2">{{ $tc('common.aircraft', 1) }}: <span v-if="item.icao">{{ item.icao }} -</span> {{ item.callSign }}</div>
          <div v-if="loadedHangarVariant && (loadedHangarVariant.variantType === 'hangar' || loadedHangarVariant.variantType === 'outside_ramps')" class="mb-2">{{ $t('dashboard.table.slice') }}: {{ item.sliceNumber }}</div>
        </div>

        <!-- Parking slice mtow -->
        <app-input
          v-if="loadedHangarVariant
           && loadedHangarVariant.variantType === 'parking'
            && loadedHangarVariant.managementType === 'slice'
            && loadedHangarVariant.sliceUnit === 'mtow'"
          id="slice-parking"
          v-model="item.event.sliceMtowValue"
          :label="$t('dashboard.table.parking_slice.mtow')"
          :append="$getMeasurementUnitItn('kg')"
          autofocus
          required
          :formatter="numberFormat"
        />

        <!-- Parking slice wingspan -->
        <app-input
          v-if="loadedHangarVariant
           && loadedHangarVariant.variantType === 'parking'
            && loadedHangarVariant.managementType === 'slice'
            && loadedHangarVariant.sliceUnit === 'wingspan'"
          id="slice-parking"
          v-model="item.event.sliceWingspanValue"
          :label="$t('dashboard.table.parking_slice.wingspan')"
          :append="$getMeasurementUnitItn('m')"
          autofocus
          required
          :formatter="numberFormat"
        />

        <validation-provider>
          <b-form-group :label="$t('dashboard.start_date')" class="mb-1" label-for="dateStart">
            <flat-pickr
              id="dateStart"
              v-model="item.event.startAt"
              :config="{
                altInput: true,
                altFormat: 'F j, Y H:i',
                dateFormat: 'Y-m-dTH:i:S+00:00', // Date en UTC
                enableTime: true,
                time_24hr: true,
                defaultDate: item.event.startAt,
                locale: $i18n.locale,
                // Permet d'avoir la date affichée en UTC
                onReady: (selectedDates, dateStr, instance) => {
                  if (selectedDates[0]) {
                    instance.setDate(new Date(selectedDates[0].toISOString()), false);
                  }
                },
              }"
              class="form-control"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider>
          <b-form-group :label="$t('dashboard.end_date')" class="mb-1" label-for="dateEnd">
            <flat-pickr
              id="dateEnd"
              v-model="item.event.endAt"
              :config="{
                altInput: true,
                altFormat: 'F j, Y H:i',
                dateFormat: 'Y-m-dTH:i:S+00:00', // Date en UTC
                enableTime: true,
                time_24hr: true,
                defaultDate: item.event.endAt,
                locale: $i18n.locale,
                // Permet d'avoir la date affichée en UTC
                onReady: (selectedDates, dateStr, instance) => {
                  if (selectedDates[0]) {
                    instance.setDate(new Date(selectedDates[0].toISOString()), false);
                  }
                },
              }"
              class="form-control"
            />
          </b-form-group>
        </validation-provider>

        <validation-provider>
          <b-form-group :label="$t('dashboard.customer')" label-for="customer" class="mb-0">
            <v-select
              v-model="item.event.customerContract"
              input-id="customer"
              :get-option-label="option => option.companyName"
              :options="[{ companyName: $t('dashboard.custom'), id: null }].concat(negotiatedRates)"
              :clearable="false"
              :disabled="!!item.event.id"
              @option:selected="item.event.clientName = null"
            />
          </b-form-group>
          <div class="mb-2"><i>{{ $t('dashboard.customer_notice') }}</i></div>
        </validation-provider>

        <app-input
          v-if="!item.event.customerContract.id"
          id="clientName"
          v-model="item.event.clientName"
          :label="$t('dashboard.custom')"
          :disabled="!!item.event.id"
        />

        <validation-provider>
          <b-form-group :label="$t('dashboard.internal_description')" label-for="internalDescription">
            <b-form-textarea
              id="internalDescription"
              v-model="item.event.internalDescription"
              :placeholder="$t('dashboard.internal_description')"
              rows="5"
            />
            <span><i>{{ $t('dashboard.internal_description_notice') }}</i></span>
          </b-form-group>
        </validation-provider>
      </template>
    </app-sidebar-form>
    </b-overlay>
  </section>
</template>

<script>
import {computed, defineComponent, ref, watch} from '@vue/composition-api'
import { omit, omitBy, pick } from 'lodash'

import AppDataTable from '@/components/AppDataTable.vue'
import AppSelect from '@/components/AppSelect.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import {
  deleteHangarCustomEventRequest, fetchHangarsVariantsCalendarSlicesRequest, fetchHangarVariantRequest,
  patchHangarCustomEventRequest,
} from '@/request/globalApi/requests/hangarRequests'

export default defineComponent({
  name: 'HangarDashboard',

  components: {
    AppSidebarForm,
    AppDataTableActions,
    AppDataTable,
    AppSelect,
  },
  setup(_props, ctx) {
    const { $i18n, $moment, $store, $router, $bvModal } = ctx.root

    const customEventSibebarOpen = ref(false)
    const customEventSelected = ref(null)

    const editCustomEvent = item => {
      customEventSelected.value = item
      reloadHangarVariant(true)
    }

    // Map state
    const dashboardLoading = computed(() => $store.state.hangarVariant.dashboardLoading)
    const hangarsVariantsOptionsLoading = computed(() => $store.state.hangarVariant.hangarsVariantsOptionsLoading)
    const dashboard = computed(() => $store.state.hangarVariant.dashboard)
    const hangarsVariantsOptions = computed(() => $store.state.hangarVariant.hangarsVariantsOptions)
    const variantsDataMeta = computed(() => pick($store.state.hangarVariant, 'previousPage', 'nextPage', 'lastPage', 'totalItems'))
    const negotiatedRates = computed(() => $store.state.negotiatedRate.negotiatedRates)
    const selectedHangarVariant = computed({
      get() {
        return $store.state.hangarVariant.selectedHangarVariant
      },
      set(hangarVariant) {
        $store.commit('hangarVariant/SET_SELECTED_HANGAR_VARIANT', hangarVariant)
      },
    })
    const loadedHangarVariantLoading = computed({
      get() {
        return $store.state.hangarVariant.loadedHangarVariantLoading
      },
      set(isLoading) {
        $store.commit('hangarVariant/SET_LOADED_HANGAR_VARIANT_LOADING', isLoading)
      },
    })
    const loadedHangarVariant = computed({
      get() {
        return $store.state.hangarVariant.loadedHangarVariant
      },
      set(hangarVariant) {
        $store.commit('hangarVariant/SET_LOADED_HANGAR_VARIANT', hangarVariant)
      },
    })

    const currentDate = ref($moment().locale($i18n.locale))
    const search = ref(null)
    const numberOfAircrafts = ref(0)

    const fetchHangarsVariantsDashboard = (queryParams = {}) => {
      const query = {
        date: $moment(currentDate.value).locale('en').format('DD-MM-YYYY'),
        ...queryParams,
      }

      if (selectedHangarVariant.value) {
        const variantId = selectedHangarVariant.value.id
        $router.replace({ query: { ...ctx.root.$router.query, vid: selectedHangarVariant.value.id, uniqueKey: `${Date.now()}` } })
        $store.dispatch('hangarVariant/fetchHangarsVariantsDashboard', { variantId, queryParams: query })
      }
    }

    const changeDate = operator => {
      currentDate.value = $moment(currentDate.value)[operator](1, 'days')
      fetchHangarsVariantsDashboard()
    }

    const fetchHangarsVariants = async page => {
      const neededRefresh = page && $store.state.hangarVariant.page !== page && !hangarsVariantsOptions[0]
      if (page) $store.commit('hangarVariant/SET_PAGE', page)
      $store.dispatch('hangarVariant/fetchHangarsVariants', neededRefresh).then(() => {
        if (hangarsVariantsOptions.value[0] && !page) {
          let hangar = hangarsVariantsOptions.value[0]

          // If a hangarVariantId is preselected by the url, select it instead of the store's selectedHangarVariant, only if it available in the options
          if (ctx.root.$route.query.vid) {
            hangar = hangarsVariantsOptions.value.find(hangarVariant => hangarVariant.id === Number(ctx.root.$route.query.vid)) || hangar
          }
          $store.commit('hangarVariant/SET_SELECTED_HANGAR_VARIANT', hangar)
        }
      })
    }
    fetchHangarsVariants().then(() => {
      fetchHangarsVariantsDashboard()
    })

    const modalDeletecustomTrip = customEventId => {
      $bvModal
        .msgBoxConfirm($i18n.t('alert.delete_confirmation.message'), {
          title: $i18n.t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: $i18n.t('common.yes'),
          cancelTitle: $i18n.t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(ok => {
          if (ok) {
            deleteHangarCustomEventRequest(customEventId).then(() => {
              fetchHangarsVariantsDashboard()
            })
          }
        })
    }

    const onSubmit = async payload => {
      const parsePayload = {
        ...omit(payload.event, 'customerContract'),
        customerContractId: payload.event.customerContract.id,
      }

      return patchHangarCustomEventRequest(parsePayload).then(() => {
        fetchHangarsVariantsDashboard()
      })
    }

    const fields = [
      { key: 'icao', label: $i18n.t('dashboard.table.icao'), sortable: true },
      { key: 'callSign', label: $i18n.t('dashboard.table.call_registration'), sortable: true },
      { key: 'customerName', label: $i18n.t('dashboard.table.custom_name'), sortable: true },
      { key: 'sliceNumber', label: $i18n.t('dashboard.table.slice'), sortable: true },
      { key: 'tow', sortable: true },
      { key: 'duration', label: $i18n.t('dashboard.table.duration'), sortable: true },
      { key: 'actions', label: $i18n.tc('action.title', 2), thClass: 'text-center', tdClass: 'text-center' },
    ]

    const numberFormat = value => value.replace(/,/g, '.')

    const reloadHangarVariant = (changeEditMode = false) => {
      loadedHangarVariantLoading.value = true
      fetchHangarVariantRequest(selectedHangarVariant.value.id).then(({ data }) => {
        loadedHangarVariant.value = data

        fetchHangarsVariantsDashboard()
      }).finally(() => {
        loadedHangarVariantLoading.value = false
        if (changeEditMode) {
          customEventSibebarOpen.value = changeEditMode
        }
      })
    }

    watch([selectedHangarVariant], () => {
      if (selectedHangarVariant.value) {
        reloadHangarVariant()
      }
    }, { immediate: true })

    return {
      dashboardLoading,
      hangarsVariantsOptionsLoading,
      dashboard,
      search,
      variantsDataMeta,
      hangarsVariantsOptions,
      selectedHangarVariant,
      currentDate,
      changeDate,
      fields,
      numberOfAircrafts,
      fetchHangarsVariants,
      fetchHangarsVariantsDashboard,
      omitBy,
      editCustomEvent,
      customEventSibebarOpen,
      customEventSelected,
      negotiatedRates,
      modalDeletecustomTrip,
      onSubmit,
      numberFormat,
      reloadHangarVariant,
      loadedHangarVariantLoading,
      loadedHangarVariant,
    }
  },
})
</script>
